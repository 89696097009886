<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Body Type</h1>
          <v-form ref="investmentForm">
            <v-label>Body Type Image</v-label>
            <div class="imageBox">
              <img
                v-if="imageFileUrl"
                :src="imageFileUrl"
                alt=""
                @click="onImagePick"
              />

              <v-file-input
                class="mt-1"
                v-if="imageFileUrl == undefined"
                v-model="imageFile"
                outlined
                prepend-inner-icon="mdi-camera"
                prepend-icon=""
                @change="onChangeImage"
                placeholder="Body Type Image"
              ></v-file-input>
            </div>
            <v-label>Body Type</v-label>
            <v-text-field
              v-model="bodyTypeName"
              outlined
              placeholder="Body Type"
              class="mt-2"
            ></v-text-field>
            <label for=""><b>Category</b></label>
            <v-select
              placeholder="Category Name"
              v-model="category"
              :items="categories"
              item-text="name"
              item-value="name"
              outlined
              class="mt-2"
            ></v-select>
            <v-btn @click="validate" v-if="!loading" large>Add Body Type</v-btn>
            <v-btn v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddBodyType",
  data: () => ({
    myCroppa: {},
    bodyTypeName: "",
    imageFileUrl: undefined,
    imageFile: undefined,
    loading: false,
    category: undefined,
    categories: [],
  }),
  methods: {
    ...mapActions(["postBodyType", "getSingleBodyType", "fetchCategory"]),
    async validate() {
      this.loading = true;
      let formData = new FormData();
      if (this.bodyTypeName != undefined) {
        formData.append("bodyTypeName", this.bodyTypeName);
      }
      if (this.imageFile != undefined) {
        formData.append("image", this.imageFile);
      }
      if (this.$route.params.id != undefined) {
        formData.append("_id", this.$route.params.id);
      }
      if (this.category != undefined) {
        formData.append("category", this.category);
      }
      await this.postBodyType(formData);

      if (this.allBodyType.status == 200 || this.allBodyType.status == 201) {
        this.$router.push("/business/bodytypes");
      }
      this.loading = false;
    },
    onChangeImage() {
      if (this.imageFile != undefined) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageFileUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.imageFile);
      } else {
        this.imageFileUrl = undefined;
      }
    },
    onImagePick() {
      this.imageFileUrl = undefined;
    },
  },
  computed: {
    ...mapGetters(["allBodyType", "allCategory"]),
  },
  async created() {
    await this.fetchCategory();
    this.categories = this.allCategory.results;
    if (this.$route.params.id) {
      await this.getSingleBodyType(this.$route.params.id);
      this.bodyTypeName = this.allBodyType.results.bodyTypeName;
      this.imageFileUrl = this.allBodyType.results.image;
      this.category = this.allBodyType.results.category;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  img {
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>